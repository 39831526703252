import styled from "styled-components"

type Props = {
  level: number
  variable?: string
  color: string
  capitalize?: boolean
  uppercase?: boolean
}

const Subtitle = styled.h5<Props>`
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-size: ${props =>
    props.level === 1 ? "16px" : props.level === 2 ? "16px" : "16px"};
  line-height: 130%;
  font-weight: 800;
  letter-spacing: 0.05em;
  color: ${props =>
    props.theme.variables[props?.variable ?? "secondary"][props?.color]
      ? props.theme.variables[props?.variable ?? "secondary"][props?.color]
      : props.theme.variables.secondary.smoke};
  text-transform: ${props =>
    props?.capitalize ? "capitalize" : props?.uppercase ? "uppercase" : "none"};
  @media (min-width: ${props => props.theme.awesomegrid.breakpoints.md}rem) {
    font-size: ${props =>
      props.level === 1 ? "24px" : props.level === 2 ? "16px" : "16px"};
  }

  @media (min-width: ${props => props.theme.awesomegrid.breakpoints.lg}rem) {
    font-size: ${props =>
      props.level === 1 ? "32px" : props.level === 2 ? "16px" : "16px"};
  }
`

export default Subtitle
