import React from "react"
import styled from "styled-components"
import Subtitle from "../Commons/Subtitle"
import Paragraph from "../Commons/Paragraph"
import { Container, Row, Col } from "react-awesome-styled-grid"
import GamesCards, { CardsProps } from "../Cards/GamesCards"
import Title from "../Commons/Title"
import Icon from "../Icons"

const ModuleWrapper = styled.div`
  padding: 24px 0px 24px;
  background-color: ${props => props.theme.variables.secondary.yellow};

  @media (min-width: ${props => props.theme.awesomegrid.breakpoints.md}rem) {
    padding: 40px 0px 40px;
  }

  @media (min-width: ${props => props.theme.awesomegrid.breakpoints.xl}rem) {
    padding: 58px 0px 62px;
  }
`
const FlexWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;

  @media (min-width: ${props => props.theme.awesomegrid.breakpoints.md}rem) {
    flex-direction: row;
  }
`

const TitleWrapper = styled.div`
  margin-bottom: 24px;

  @media (min-width: ${props => props.theme.awesomegrid.breakpoints.md}rem) {
    margin-bottom: 32px;
  }

  @media (min-width: ${props => props.theme.awesomegrid.breakpoints.md}rem) {
    margin-bottom: 0px;
    margin-right: 16px;
  }
`
const LinkWrapper = styled.div`
  display: flex;
  align-items: center;
`

const LinkItem = styled.div`
  display: flex;
  align-items: center;
  margin-left: 67px;

  &:first-child {
    margin-left: 0;
  }

  & > svg {
    margin-right: 12px;
  }

  @media (max-width: ${props => props.theme.awesomegrid.breakpoints.md}rem) {
    & > svg {
      width: 16px;
      margin-right: 8px;
    }
  }
`

const Link = styled.a`
  text-decoration-line: underline;
  text-underline-offset: 1px;
  cursor: pointer;
`

const FooterColors = styled.div``

type FooterItemProps = {
  variable?: string
  color: string
}

const FooterItem = styled.div<FooterItemProps>`
  height: 5px;
  background-color: ${props =>
    props.theme.variables[props?.variable ?? "secondary"][props?.color]
      ? props.theme.variables[props?.variable ?? "secondary"][props?.color]
      : props.theme.variables.primary.black};
`

type Props = {
  title: string
  link: Array<{
    icon: { name: string; color: string; size: string }
    text: string
    link: string
  }>
}

const GamesHeader: React.FC<Props> = ({ title, link }) => {
  return (
    <>
      <ModuleWrapper>
        <Container>
          <FlexWrapper>
            <TitleWrapper>
              <Title level={1} variable="primary" color="black" uppercase>
                {title}
              </Title>
            </TitleWrapper>
            <LinkWrapper>
              {link.map((item, index) => (
                <LinkItem>
                  <Icon
                    icon={item.icon.name}
                    color={item.icon.color}
                    size={item.icon.size || 48}
                  />
                  <Link>
                    <Paragraph
                      level={1}
                      variable="primary"
                      color="black"
                      capitalize
                      weight="500"
                    >
                      {item.text}
                    </Paragraph>
                  </Link>
                </LinkItem>
              ))}
            </LinkWrapper>
          </FlexWrapper>
        </Container>
      </ModuleWrapper>
      <FooterColors>
        <FooterItem variable="secondary" color="orange" />
        <FooterItem variable="secondary" color="blue" />
        <FooterItem variable="secondary" color="pink" />
        <FooterItem variable="secondary" color="purple" />
      </FooterColors>
    </>
  )
}

export default GamesHeader
