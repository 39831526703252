// If you don't want to use TypeScript you can delete this file!
import React, { useEffect } from "react"
import { PageProps, graphql } from "gatsby"
import { Link, Trans, useTranslation } from "gatsby-plugin-react-i18next"

import Layout from "../components/layout"
import Seo from "../components/seo"
import GamesHeader from "../components/GamesHeader"
import GamesModule from "../components/GamesModule"

import GamesImage1 from "../images/GamesCards/gamescard-1.png"
import GamesImage2 from "../images/GamesCards/gamescard-2.png"
import GamesImage3 from "../images/GamesCards/gamescard-3.png"
import GamesImage4 from "../images/GamesCards/gamescard-4.png"
import BuyNowModule from "../components/BuyNowModule"

import RERUNNERTH from "../images/Hero/RERUNNER_thumb.jpg"
import JFGTH from "../images/Hero/JFG_thumb.png"
import DRAGONFANTASY2 from "../images/GamesCards/DRAGONFANTASY2.png"
import BIT_TRIPBEAT from "../images/GamesCards/BIT.TRIPBEAT.png"
import BIT_TRIPCOMPLETE from "../images/GamesCards/BIT.TRIPCOMPLETE.png"
import BIT_TRIPCORE from "../images/GamesCards/BIT.TRIPCORE.png"
import BIT_TRIPFATE from "../images/GamesCards/BIT.TRIPFATE.png"
import BIT_TRIPFLUX from "../images/GamesCards/BIT.TRIPFLUX.png"
import BIT_TRIPRUNNER from "../images/GamesCards/BIT.TRIPRUNNER.png"
import BIT_TRIPSAGA from "../images/GamesCards/BIT.TRIPSAGA.png"
import BIT_TRIPVOID from "../images/GamesCards/BIT.TRIPVOID.png"
import BUBSY from "../images/GamesCards/BUBSY.png"
import CARDOFDARKNESS from "../images/GamesCards/CARDOFDARKNESS.png"
import DESTRUCAMUNDO from "../images/GamesCards/DESTRUCAMUNDO.png"
import DRAGONFANTASY1 from "../images/GamesCards/DRAGONFANTASY1.png"
import DRIVE_DRIVE_DRIVE from "../images/GamesCards/DRIVEDRIVEDRIVE.png"
import GO_GO_COMMANDERVIDEO from "../images/GamesCards/GOGOCOMMANDERVIDEO.png"
import LASERLIFE from "../images/GamesCards/LASERLIFE.png"
import LILTLINE from "../images/GamesCards/LILTLINE.png"
import RUNNER2 from "../images/GamesCards/RUNNER2.png"
import RUNNER3 from "../images/GamesCards/RUNNER3.png"
import SHIFTDX from "../images/GamesCards/SHIFTDX.png"
import SHUTSHIMI from "../images/GamesCards/SHUTSHIMI.png"
import SPACEDAVE from "../images/GamesCards/SPACEDAVE.png"
import THARSIS2 from "../images/GamesCards/THARSIS 2.png"
import WOAHDAVE from "../images/GamesCards/WOAHDAVE.png"

const GamesPage: React.FC<PageProps> = () => {
  const { t } = useTranslation()

  useEffect(() => {
    if (window !== undefined) {
      require("lazysizes/plugins/attrchange/ls.attrchange.js")
      require("lazysizes/plugins/bgset/ls.bgset")
      require("lazysizes")
    }
  }, [])

  return (
    <Layout>
      <Seo
        title="Choice Provisions | Games"
        description="Choice Provisions was founded in 2007, making us about 417 in game industry years. We've taken the wisdom we've accrued over time and focused it towards developing the best games we can in the most sustainable way possible."
      />

      <GamesHeader
        title="GAMEs LIBRARY"
        link={
          [
            // {
            //   icon: { name: "shop", color: "#000000", size: "32" },
            //   text: "Buy Gift Card",
            //   link: "",
            // },
            // {
            //   icon: { name: "qr-code", color: "#000000", size: "32" },
            //   text: "Redeem Code",
            //   link: "",
            // },
          ]
        }
      />

      <GamesModule
        // button_text="load more"
        padding_top={{ sm: 36, md: 52, lg: 72 }}
        padding_bottom={{ sm: 36, md: 66, lg: 66 }}
        cards={
          [
            {
              col: 6,
              CardsData: {
                trailer: "https://www.youtube.com/watch?v=zbia07Cq6_w",
                image: BIT_TRIPBEAT,
                title: "BIT.TRIP BEAT",
                description:
                  "Redefining the paddle genre, BIT.TRIP BEAT marries classic gameplay with rhythm/music interaction and stunning visuals for a truly synaesthetic experience unlike any other.\n\nBIT.TRIP BEAT marks the beginning of CommanderVideo’s epic journey of the soul.",
                avaliable_platforms: [
                  {
                    icon: "ds",
                    size: "48",
                    link: "https://www.nintendo.com/games/detail/bittrip-saga-3ds/",
                  },
                  {
                    icon: "microsoft",
                    size: "48",
                    link: "https://store.steampowered.com/app/63700/BITTRIP_BEAT/",
                  },
                  {
                    icon: "ps4",
                    size: "48",
                    link: "https://store.playstation.com/en-us/product/UP2148-CUSA02752_00-THEBITTRIP000000/",
                  },
                  {
                    icon: "Nintento-Switch",
                    size: "48",
                    link: "https://www.nintendo.com/games/detail/bittrip-beat-switch/",
                  },
                ],
              },
            },
            {
              col: 6,
              CardsData: {
                trailer: "https://www.youtube.com/watch?v=zbia07Cq6_w",
                image: BIT_TRIPCORE,
                title: "BIT.TRIP CORE",
                description:
                  "BIT.TRIP CORE visits a darker place in CommanderVideo's saga, taking the player into unfamiliar territory as they use NES-style controls to blast their way through the barrage of Beats in this rhythm-music adventure.\n\nCORE is the second installment in CommanderVideo's soul quest.",
                avaliable_platforms: [
                  {
                    icon: "ds",
                    size: "48",
                    link: "https://www.nintendo.com/games/detail/bittrip-saga-3ds/",
                  },
                  {
                    icon: "microsoft",
                    size: "48",
                    link: "https://store.steampowered.com/app/205060/BITTRIP_CORE/",
                  },
                  {
                    icon: "ps4",
                    size: "48",
                    link: "https://store.playstation.com/en-us/product/UP2148-CUSA02752_00-THEBITTRIP000000/",
                  },
                  {
                    icon: "Nintento-Switch",
                    size: "48",
                    link: "https://www.nintendo.com/games/detail/bittrip-core-switch/",
                  },
                ],
              },
            },
            {
              col: 6,
              CardsData: {
                trailer: "https://www.youtube.com/watch?v=zbia07Cq6_w",
                image: BIT_TRIPVOID,
                title: "BIT.TRIP VOID",
                description:
                  "In a control scheme brand new to the series, yet wholly familiar to gamers, BIT.TRIP VOID gives the player the freedom to move about the screen wherever they desire, interacting with the music in brand new ways. The classic gameplay aesthetic remains, as color—and the absence of color—are explored to the fullest.\n\nBIT.TRIP VOID is the third installment in the retro-arcade BIT.TRIP saga.",
                avaliable_platforms: [
                  {
                    icon: "ds",
                    size: "48",
                    link: "https://www.nintendo.com/games/detail/bittrip-saga-3ds/",
                  },
                  {
                    icon: "microsoft",
                    size: "48",
                    link: "https://store.steampowered.com/app/205070/BITTRIP_VOID/",
                  },
                  {
                    icon: "ps4",
                    size: "48",
                    link: "https://store.playstation.com/en-us/product/UP2148-CUSA02752_00-THEBITTRIP000000/",
                  },
                  {
                    icon: "Nintento-Switch",
                    size: "48",
                    link: "https://www.nintendo.com/games/detail/bittrip-void-switch/",
                  },
                ],
              },
            },
            {
              col: 6,
              CardsData: {
                trailer: "https://www.youtube.com/watch?v=zbia07Cq6_w",
                image: BIT_TRIPFATE,
                title: "BIT.TRIP FATE",
                description:
                  "FATE is the penultimate chapter in the six-part BIT.TRIP series and will leave you wondering what’s next.\n\nA fresh take on the dual-stick shooter genre, FATE mashes sides-scrolling action with 360 degree firepower in CommanderVideo’s most dangerous journey yet!",
                avaliable_platforms: [
                  {
                    icon: "ds",
                    size: "48",
                    link: "https://www.nintendo.com/games/detail/bittrip-saga-3ds/",
                  },
                  {
                    icon: "microsoft",
                    size: "48",
                    link: "https://store.steampowered.com/app/205080/BITTRIP_FATE/",
                  },
                  {
                    icon: "ps4",
                    size: "48",
                    link: "https://store.playstation.com/en-us/product/UP2148-CUSA02752_00-THEBITTRIP000000/",
                  },
                  {
                    icon: "Nintento-Switch",
                    size: "48",
                    link: "https://www.nintendo.com/games/detail/bittrip-fate-switch/",
                  },
                ],
              },
            },
            {
              col: 6,
              CardsData: {
                trailer: "https://www.youtube.com/watch?v=zbia07Cq6_w",
                image: BIT_TRIPFLUX,
                title: "BIT.TRIP FLUX",
                description:
                  "The BIT.TRIP series comes full-circle with BIT.TRIP FLUX: CommanderVideo's final adventure.\n\nGet ready for classic paddle-based gameplay as CommanderVideo returns to the source.",
                avaliable_platforms: [
                  {
                    icon: "ds",
                    size: "48",
                    link: "https://www.nintendo.com/games/detail/bittrip-saga-3ds/",
                  },
                  {
                    icon: "ps4",
                    size: "48",
                    link: "https://store.playstation.com/en-us/product/UP2148-CUSA02752_00-THEBITTRIP000000/",
                  },
                  {
                    icon: "Nintento-Switch",
                    size: "48",
                    link: "https://www.nintendo.com/games/detail/bittrip-flux-switch/",
                  },
                ],
              },
            },
            {
              col: 6,
              CardsData: {
                trailer: "https://www.youtube.com/watch?v=tMrxh21wj_0",
                image: LILTLINE,
                title: "Lilt Line",
                description:
                  "Lilt line is an award winning rhythm racing game with a dubstep soundtrack and a visual aesthetic that will confound your senses and rock your soul.\n\nControl the silky-smooth rhythm line and do your best to stay on the track as you race to the finish, tapping the button to the beat of the genuinely filthy soundtrack pumped out by genre superstars, 16bit.",
                avaliable_platforms: [
                  {
                    icon: "Apple",
                    size: "48",
                    link: "https://apps.apple.com/us/app/lilt-line/id313123539",
                  },
                ],
              },
            },
            //           {
            //             col: 6,
            //             CardsData: {
            //               trailer: "https://www.youtube.com/watch?v=Z-fsVXu70sc",
            //               image: GamesImage1,
            //               title: "Bloktonik",
            //               description:
            //                 "Bloktonik challenges your sense of gravity and keeps you on your toes as Bloks fall in toward the center from 4 different directions.\n\nMatch up colors and discover secret bonuses to skyrocket your score!\n\nCollect special power-up bombs to detonate your way out of tough situations!\n\nFeaturing the musical stylings of chiptune mastermind Bit Shifter, and the smooth, deep house beats of renowned UK DJ, Jimpster!",
            //               avaliable_platforms: [
            //               ],
            //             },
            //           },
            {
              col: 6,
              CardsData: {
                trailer: "https://www.youtube.com/watch?v=2dHWiLmW1GQ",
                image: BIT_TRIPSAGA,
                title: "BIT.TRIP SAGA",
                description:
                  "BIT.TRIP SAGA is a compilation of all 6 award-winning BIT.TRIP games: BEAT, CORE, VOID, RUNNER, FATE, and FLUX! BIT.TRIP SAGA introduces 3D functionality to the series, giving the environments radical new depth.\n\nExperience the crushing difficulty of arcade-style action over a rockin’ chiptune soundtrack and follow CommanderVideo’s journey from before... to after.",
                avaliable_platforms: [
                  {
                    icon: "ds",
                    size: "48",
                    link: "https://www.nintendo.com/games/detail/bittrip-saga-3ds/",
                  },
                ],
              },
            },
            {
              col: 6,
              CardsData: {
                trailer: "https://www.youtube.com/watch?v=2dHWiLmW1GQ",
                image: BIT_TRIPCOMPLETE,
                title: "BIT.TRIP COMPLETE",
                description:
                  "BIT.TRIP COMPLETE is a compilation of all 6 award-winning BIT.TRIP games: BEAT, CORE, VOID, RUNNER, FATE, and FLUX!\n\nThis COMPLETE version includes exclusive gameplay challenges, a video gallery with cutscenes and game trailers, an image gallery with concept art, an audio gallery featuring fan remixes, original songs, and demos of BIT.TRIP songs, and a “letters to the fans” section which explores the story behind CommanderVideo’s epic journey.",
                avaliable_platforms: [
                  {
                    icon: "wii",
                    size: "48",
                    link: "https://www.amazon.com/BIT-TRIP-COMPLETE-WII/dp/B00LCEK9F4/ref=sr_1_1?keywords=bit.trip+complete&qid=1636739067&sr=8-1",
                  },
                ],
              },
            },

            {
              col: 6,
              CardsData: {
                trailer: "",
                image: GO_GO_COMMANDERVIDEO,
                title: "CommanderVideo",
                description:
                  "Given its endless and randomly generated nature, CommanderVideo is quite possibly the longest game in history.\n\nWhether or not that absurd statement becomes fact is entirely up to the players, and how well they manage to avoid the many treacherous pitfalls and devious spiders that lay ahead of them.",
                avaliable_platforms: [],
              },
            },
            {
              col: 6,
              CardsData: {
                trailer: "https://www.youtube.com/watch?v=zbia07Cq6_w",
                image: BIT_TRIPRUNNER,
                title: "BIT.TRIP RUN!",
                description:
                  "BIT.TRIP RUN! is the iOS version of the much-loved, super awesome Runner2. Although it features the same art style and strange sense of humor, it’s much more than just a port. We’ve revamped the controls, tweaked the level design, and gone to great lengths to ensure that Reverse Merman remains as majestic and lovable as ever. Oh, that wonderful fish.",
                avaliable_platforms: [],
              },
            },
            {
              col: 6,
              CardsData: {
                trailer: "https://www.youtube.com/watch?v=TOjLLrJwW0g",
                image: WOAHDAVE,
                title: "Woah Dave!",
                description:
                  "From the folks behind the BIT.TRIP series comes the frantic insanity that is Woah Dave! Help our hero, Dave Lonuts, survive an insane alien invasion and line his pockets with shiny pennies.\n\nHurl alien eggs, skull bombs, and explosive WOAH blocks to stay alive and sky rocket yourself to high score fame and fortune.",
                avaliable_platforms: [
                  {
                    icon: "microsoft",
                    size: "48",
                    link: "https://store.steampowered.com/app/315110/Woah_Dave/",
                  },
                  {
                    icon: "ps4",
                    size: "48",
                    link: "https://store.playstation.com/en-us/product/UP2148-CUSA01274_00-WOAHDAVE20000000/",
                  },
                  {
                    icon: "ds",
                    size: "48",
                    link: "https://www.nintendo.com/games/detail/woah-dave-3ds/",
                  },
                ],
              },
            },
            {
              col: 6,
              CardsData: {
                trailer: "https://www.youtube.com/watch?v=oeGpusBogfY",
                image: DESTRUCAMUNDO,
                title: "Destructamundo",
                description:
                  "Destructamundo is a game of chain reactions and massive explosions. Set off a warhead and watch the fireworks as you cause as much widespread damage as possible by setting off long, winding combos that reach throughout the cosmos. Destroy an entire planetary system in a single shot (or, if you’re not so cunning, destroy it in a couple of shots). Why would you do such a horrible thing, you might ask? For shiny gold medals, of course!",
                avaliable_platforms: [
                  {
                    icon: "microsoft",
                    size: "48",
                    link: "https://store.steampowered.com/app/323020/Destructamundo/",
                  },
                ],
              },
            },
            //           {
            //             col: 6,
            //             CardsData: {
            //               trailer: "",
            //               image: GamesImage1,
            //               title: "Talli",
            //               description:
            //                 "Talli is a light-weight personal tracking app that lets you keep tabs on anything you like.\n\nWhether using Talli on the iPhone or the Apple Watch, its minimal interface will help you keep track of anything you like, including: how many miles you’ve run in a week, how many drinks you’ve had this month, or how many hugs and kisses you’ve enjoyed today.",
            //               avaliable_platforms: [
            //               ],
            //             },
            //           },
            {
              col: 6,
              CardsData: {
                trailer: "https://www.youtube.com/watch?v=GOn6-OzXgGQ",
                image: DRAGONFANTASY1,
                title: "Dragon Fantasy: The Volumes of Westeria",
                developer: "Muteki",
                developer_link: "https://muteki.games/",
                description:
                  "Experience an all-new old adventure! Fight your way through hordes of strange, silly, and scary monsters! Explore the caves, castles, and dungeons of an ancient evil! Meet warriors, princes, pirates, zombies, and crazy old woodsmen in a huge world of epic retro adventure!",
                avaliable_platforms: [
                  {
                    icon: "microsoft",
                    size: "48",
                    link: "https://store.steampowered.com/app/327980/Dragon_Fantasy_The_Volumes_of_Westeria/",
                  },
                ],
              },
            },
            {
              col: 6,
              CardsData: {
                trailer: "https://www.youtube.com/watch?v=OuqnnZTCvTE",
                image: SHUTSHIMI,
                title: "Shutshimi",
                developer: "Neon Deity",
                developer_link: "https://neondeity.com/",
                description:
                  "Shütshimi is a randomized shoot'em up about a muscle-bound fish with memory problems defending the seven seas. Defeat or navigate through waves of enemies, collecting new upgrades every 10 seconds that change up the gameplay as you battle your way through each of the game's bosses.",
                avaliable_platforms: [
                  {
                    icon: "microsoft",
                    size: "48",
                    link: "https://store.steampowered.com/app/332530/Shutshimi/",
                  },
                  {
                    icon: "wii-u",
                    size: "48",
                    link: "https://www.nintendo.com/games/detail/shutshimi-wii-u/",
                  },
                  {
                    icon: "ps4",
                    size: "48",
                    link: "https://store.playstation.com/en-us/product/UP2148-CUSA02535_00-SHUTSHIMI0000000/",
                  },
                ],
              },
            },

            {
              col: 6,
              CardsData: {
                trailer: "https://www.youtube.com/watch?v=90lMLg_Bdok",
                image: GO_GO_COMMANDERVIDEO,
                title: "Go! Go! CommanderVideo!",
                description:
                  "CommanderVideo of BIT.TRIP and Runner2 fame returns, and he'll do whatever it takes to get to the castle!\n\nGo! Go! CommanderVideo is a tough-as-nails single-tap runner where all you need to do is jump to survive... can you get to the castle?!",
                avaliable_platforms: [
                  {
                    icon: "Apple",
                    size: "48",
                    link: "https://apps.apple.com/us/app/go-go-commandervideo/id929840668?ls=1",
                  },
                ],
              },
            },

            //           {
            //             col: 6,
            //             CardsData: {
            //               trailer: "",
            //               image: GamesImage1,
            //               title: "make13",
            //               description:
            //                 "If you can add a few numbers together, then you're about to take an addictive plunge into the world of make13!\n\nFollow your pal Wilson Girdlesprings on a lovely walk while you shift tiles up or down to add or subtract one. Select tiles that add up to 13, and collect shiny coins for each tile you use! If you're really good, you'll use the glowing Bonus Tiles in a strategic way to earn a triple coin bonus...use as many tiles in one shot to make13 as you can for the highest score!",
            //               avaliable_platforms: [
            //               ],
            //             },
            //           },

            {
              col: 6,
              CardsData: {
                trailer: "https://www.youtube.com/watch?v=zMnA0zA7Yko",
                image: DRAGONFANTASY2,
                title: "Dragon Fantasy: The Black Tome of Ice",
                developer: "Muteki",
                developer_link: "https://muteki.games/",
                description:
                  "Join Ogden and the heroes of Westeria in this classic JRPG epic, Dragon Fantasy: The Black Tome of Ice. Travel through the frozen wastelands of Tundaria and defeat oncoming hordes of demons and huge bosses that threaten the entire world of Dragon Fantasy.",
                avaliable_platforms: [
                  {
                    icon: "microsoft",
                    size: "48",
                    link: "https://store.steampowered.com/app/328000/Dragon_Fantasy_The_Black_Tome_of_Ice/",
                  },
                  {
                    icon: "wii-u",
                    size: "48",
                    link: "https://www.nintendo.com/games/detail/dragon-fantasy-the-black-tome-of-ice-wii-u/",
                  },
                ],
              },
            },

            {
              col: 6,
              CardsData: {
                trailer: "https://www.youtube.com/watch?v=JXdf6rqhdhg",
                image: DRIVE_DRIVE_DRIVE,
                title: "Drive!Drive!Drive!",
                developer: "Different Cloth",
                developer_link: "http://www.differentcloth.com/",
                description:
                  "Drive!Drive!Drive! is a racing game unlike any other, where players must drive their cars to victory across multiple tracks at the same time.",
                avaliable_platforms: [
                  {
                    icon: "ps4",
                    size: "48",
                    link: "https://store.steampowered.com/app/356460/DriveDriveDrive/",
                  },
                  {
                    icon: "microsoft",
                    size: "48",
                    link: "https://store.playstation.com/en-us/product/UP2148-CUSA02534_00-DRIVEDRIVEDRIVE0/",
                  },
                ],
              },
            },
            {
              col: 6,
              CardsData: {
                trailer: "https://www.youtube.com/watch?v=NSduX9fZfEg",
                image: SHIFTDX,
                title: "Shift DX",
                developer: "Moragami",
                developer_link: "https://www.moragami.com/",
                description:
                  "Many have tried to emulate the gameplay of Shift since its first release in 2008, but none have managed to capture the frantic, mind-bending nature of the series. Players are tasked with making their way through a series of perplexing rooms, equipped with nothing more than their ability to 'shift' the playing field. Is a wall blocking you from progressing? Flip that level upside down and turn that wall into a pit!\n\nOf course, that's only the beginning of the challenges players will encounter in Shift DX. The road to victory is paved with mysterious items, complex environmental puzzles, and a variety of deadly traps.",
                avaliable_platforms: [
                  {
                    icon: "ds",
                    size: "48",
                    link: "https://www.nintendo.com/games/detail/shift-dx-3ds/",
                  },
                ],
              },
            },
            {
              col: 6,
              CardsData: {
                trailer: "https://www.youtube.com/watch?v=nwjHB4QWfKc",
                image: SPACEDAVE,
                title: "Space Dave!",
                description:
                  "Space Dave!, the spiritual successor to the hit game Woah Dave!, is a modern take on the fixed shooters of yesteryear. Defend your ground against the alien onslaught! Protect your terrain from their endless arsenal of weapons!",
                avaliable_platforms: [
                  {
                    icon: "Nintento-Switch",
                    size: "48",
                    link: "https://www.nintendo.com/games/detail/space-dave-switch/",
                  },
                ],
              },
            },
            {
              col: 6,
              CardsData: {
                trailer: "https://www.youtube.com/watch?v=pLJFHqs8V2s",
                image: LASERLIFE,
                title: "Laserlife",
                description:
                  "Remember what it means to be a human in this interactive biography of a dead astronaut found in deep space by future intelligences who have no concept of humankind.",
                avaliable_platforms: [
                  {
                    icon: "microsoft",
                    size: "48",
                    link: "https://store.steampowered.com/app/323040/Laserlife/",
                  },
                  {
                    icon: "ps4",
                    size: "48",
                    link: "https://store.playstation.com/en-us/product/UP2148-CUSA02862_00-GAME000000000000/",
                  },
                  {
                    icon: "Xbox",
                    size: "48",
                    link: "https://www.microsoft.com/en-us/p/laserlife/c1928v1ngn41?activetab=pivot:overviewtab",
                  },
                ],
              },
            },
            {
              col: 6,
              CardsData: {
                trailer: "https://www.youtube.com/watch?v=zbia07Cq6_w",
                image: GamesImage1,
                title: "The BIT.TRIP Series",
                description:
                  "THE BIT.TRIP is the ultimate way to play all six games in the award-winning BIT.TRIP series, including BIT.TRIP RUNNER, the first auto-running rhythm platformer on Earth!\n\nThis tough-as-nails collection of retro-inspired music/rhythm games will make you laugh, cry, scream, curse, and perhaps even bleed.",
                avaliable_platforms: [
                  {
                    icon: "ps4",
                    size: "48",
                    link: "https://store.playstation.com/en-us/product/UP2148-CUSA02752_00-THEBITTRIP000000/",
                  },
                ],
              },
            },
            {
              col: 6,
              CardsData: {
                trailer: "https://www.youtube.com/watch?v=zbia07Cq6_w",
                image: BIT_TRIPRUNNER,
                title: "BIT.TRIP RUNNER",
                description:
                  "RUNNER is the IGF award-winning rhythm/music platformer that helped to define the auto-runner genre and marks the first fully character-based adventure for CommanderVideo!",
                avaliable_platforms: [
                  {
                    icon: "ds",
                    size: "48",
                    link: "https://www.nintendo.com/games/detail/bittrip-saga-3ds/",
                  },
                  {
                    icon: "microsoft",
                    size: "48",
                    link: "https://store.steampowered.com/app/63710/BITTRIP_RUNNER/",
                  },
                  {
                    icon: "ps4",
                    size: "48",
                    link: "https://store.playstation.com/en-us/product/UP2148-CUSA02752_00-THEBITTRIP000000/",
                  },
                  {
                    icon: "Nintento-Switch",
                    size: "48",
                    link: "https://www.nintendo.com/games/detail/bittrip-runner-switch/",
                  },
                ],
              },
            },
            {
              col: 6,
              CardsData: {
                trailer: "https://www.youtube.com/watch?v=1HI60A2oNmo",
                image: RUNNER2,
                title:
                  "BIT.TRIP PRESENTS… RUNNER2: FUTURE LEGEND OF RHYTHM ALIEN",
                description:
                  "Runner2 is the sequel to the critically acclaimed and IGF award winning BIT.TRIP RUNNER.\n\nIn the style of the BIT.TRIP series, Runner2 is a rhythm-music platforming game.\n\nPlayers will run through fantastic environments, using brand new moves, to brand new soundtracks as they run, jump, slide, kick, and soar toward the goal of tracking down the Timbletot and thwarting his evil plans to destroy not only our world, but every world there is.",
                avaliable_platforms: [
                  {
                    icon: "microsoft",
                    size: "48",
                    link: "https://store.steampowered.com/app/218060/BITTRIP_Presents_Runner2_Future_Legend_of_Rhythm_Alien/",
                  },
                  {
                    icon: "ps4",
                    size: "48",
                    link: "https://store.playstation.com/en-us/product/UP4457-CUSA04816_00-RUNNER2000000000/",
                  },
                  {
                    icon: "Xbox",
                    size: "48",
                    link: "https://www.microsoft.com/en-us/p/Runner2/BPJC0C7GNBFB?activetab=pivot:overviewtab",
                  },
                ],
              },
            },
            {
              col: 6,
              CardsData: {
                trailer: "https://www.youtube.com/watch?v=xWVyFok2JxY",
                image: BUBSY,
                title: "Bubsy: Paws on Fire!",
                description:
                  "From the developers of the BIT.TRIP series comes Bubsy: Paws on Fire!, the latest and greatest in bobcat-based gaming.",
                avaliable_platforms: [
                  {
                    icon: "Nintento-Switch",
                    size: "48",
                    link: "https://www.nintendo.com/games/detail/bubsy-paws-on-fire-switch/",
                  },
                  {
                    icon: "ps4",
                    size: "48",
                    link: "https://store.playstation.com/en-us/product/UP2035-CUSA14048_00-BUBSYPAWS0000001/",
                  },
                ],
              },
            },
            {
              col: 6,
              CardsData: {
                trailer: "https://www.youtube.com/watch?v=rymHRU6HGgs",
                image: CARDOFDARKNESS,
                title: "Card of Darkness",
                description:
                  "Embark on an epic hand-animated adventure from Zach Gage, Pendleton Ward, and Choice Provisions.\n\nCast powerful spells, slay fantastic monsters, discover ancient secrets, and ultimately save the world — just by picking up the right cards.\n\nCard of Darkness is a full-featured adventure designed around an accessible, minimalist, card game core.",
                avaliable_platforms: [
                  {
                    icon: "apple-arcade",
                    size: "48",
                    link: "https://apps.apple.com/us/app/card-of-darkness/id1364257627#?platform=iphone",
                  },
                ],
              },
            },
            {
              col: 6,
              CardsData: {
                trailer: "https://www.youtube.com/watch?v=5ES2KNejcbI&amp;t=3s",
                image: RUNNER3,
                title: "Runner3",
                description:
                  "The rhythm-music platformer gameplay of BIT.TRIP RUNNER and Runner2 is back and better than ever!\n\nThe Timbletot is determined to rid the multiverse of all its love and happiness. CommanderVideo, a lover if ever there were one, refuses to let this happen. He’s ready to jump, double-jump, wall-jump, slide-jump, slide, kick, float, fly, and dance his way to victory, all across a medley of thrilling, challenging, and joyous levels!",
                avaliable_platforms: [
                  {
                    icon: "Nintento-Switch",
                    size: "48",
                    link: "https://www.nintendo.com/games/detail/runner3-switch/",
                  },
                  {
                    icon: "ps4",
                    size: "48",
                    link: "https://store.playstation.com/en-us/product/UP2148-CUSA12098_00-RUNNER3000000000/",
                  },
                  {
                    icon: "microsoft",
                    size: "48",
                    link: "https://store.steampowered.com/app/516130/Runner3/",
                  },
                ],
              },
            },
            {
              col: 6,
              CardsData: {
                trailer: "https://www.youtube.com/watch?v=LvFNn4PZa4A",
                image: THARSIS2,
                title: "Tharsis",
                description:
                  "Tharsis is a turn-based space strategy game. With dice. And cannibalism. It puts you in control of humanity’s first mission to Mars, just as it’s struck by a micrometeoroid storm. You must guide your crew through disasters, food shortages, and the unforgiving nature of space — all while maintaining your sanity amidst cryptic signals and warnings from Mars.\n\nDefy the odds, make the difficult decisions, and embark on the most important mission in human history.",
                avaliable_platforms: [
                  {
                    icon: "microsoft",
                    size: "48",
                    link: "https://store.steampowered.com/app/323060/Tharsis/",
                  },
                  {
                    icon: "epic-games",
                    size: "48",
                    link: "https://www.epicgames.com/store/en-US/p/tharsis",
                  },
                  {
                    icon: "ps4",
                    size: "48",
                    link: "https://store.playstation.com/en-us/product/UP2148-CUSA02096_00-THARSIS000000000/",
                  },
                  {
                    icon: "Apple",
                    size: "48",
                    link: "https://apps.apple.com/us/app/tharsis/id956621095",
                  },
                  {
                    icon: "Nintento-Switch",
                    size: "48",
                    link: "https://www.nintendo.com/games/detail/tharsis-switch/",
                  },
                ],
              },
            },
            {
              col: 6,
              CardsData: {
                trailer: "https://www.youtube.com/watch?v=YqgCESVWNNI",
                image: JFGTH,
                title: "Hextech Mayhem: A League of Legends Story",
                description:
                  "League of Legends' Ziggs brings his unique brand of chaos to the once-peaceful city of Piltover!",
                avaliable_platforms: [
                  {
                    icon: "Nintento-Switch",
                    size: "48",
                    link: "https://www.nintendo.com/games/detail/hextech-mayhem-a-league-of-legends-story-switch/",
                  },
                  {
                    icon: "epic-games",
                    size: "40",
                    link: "https://www.epicgames.com/store/p/hextech-mayhem-a-league-of-legends-story",
                  },
                  {
                    icon: "microsoft",
                    size: "40",
                    link: "https://store.steampowered.com/app/1651960/Hextech_Mayhem_A_League_of_Legends_Story/",
                  },
                  {
                    icon: "Gog",
                    size: "48",
                    link: "https://www.gog.com/game/hextech_mayhem_a_league_of_legends_story",
                  },
                ],
              },
            },
            {
              col: 6,
              CardsData: {
                trailer: "https://www.youtube.com/watch?v=AsLm6H1W1m4",
                image: RERUNNERTH,
                title: "BIT.TRIP RERUNNER",
                description:
                  "Over 150 new levels, each with increasing challenges\n\nUnleash your creativity with the RUNNER-MAKER tool, create and share your own levels.\n\nNew gameplay mechanics inspired by the original BIT.TRIP games\n\n+ The original RUNNER",
                avaliable_platforms: [
                  {
                    icon: "Steam",
                    size: "48",
                    link: "https://store.steampowered.com/app/2222540/BITTRIP_RERUNNER/",
                  },
                ],
              },
            },
          ].reverse() /* Don't feel like reversing this by hand. */
        }
      />

      {/* <BuyNowModule
        title="summer sales bundles"
        description="When our games are on sale, we'll be sure to share that news here!"
      /> */}
    </Layout>
  )
}

export default GamesPage

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
