import React from "react"
import styled from "styled-components"
import Subtitle from "../Commons/Subtitle"
import Paragraph from "../Commons/Paragraph"
import { Container, Row, Col } from "react-awesome-styled-grid"
import GamesCards, { CardsProps } from "../Cards/GamesCards"
import Title from "../Commons/Title"
import Button from "../Commons/Button"

import Tharsis from "../../images/GamesCards/tharsis.png"

type ModuleWrapperProps = {
  padding_top: { sm: number; md: number; lg: number }
  padding_bottom: { sm: number; md: number; lg: number }
}

const ModuleWrapper = styled.div<ModuleWrapperProps>`
  padding-top: ${props => props.padding_top.sm}px;
  padding-bottom: ${props => props.padding_bottom.sm}px;
  position: relative;

  @media (min-width: ${props => props.theme.awesomegrid.breakpoints.md}rem) {
    padding-top: ${props => props.padding_top.md}px;
    padding-bottom: ${props => props.padding_bottom.md}px;
  }

  @media (min-width: ${props => props.theme.awesomegrid.breakpoints.lg}rem) {
    padding-top: ${props => props.padding_top.lg}px;
    padding-bottom: ${props => props.padding_bottom.lg}px;
  }
`

const TitleWrapper = styled.div`
  margin-bottom: 43px;
  text-align: center;


  @media (min-width: ${props => props.theme.awesomegrid.breakpoints.md}rem) {
    margin-bottom: 40px;
  }

  @media (min-width: ${props => props.theme.awesomegrid.breakpoints.lg}rem) {
    text-align: left;
    margin-bottom: 48px;
  }
`

const CardWrapper = styled.div`
  margin-bottom: 40px;

  @media (min-width: ${props => props.theme.awesomegrid.breakpoints.md}rem) {
    margin-bottom: 24px;
  }

  @media (min-width: ${props => props.theme.awesomegrid.breakpoints.lg}rem) {
    margin-bottom: 88px;
    height: 100%;
  }
`

const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  margin-top: 16px;

  @media (min-width: ${props => props.theme.awesomegrid.breakpoints.lg}rem) {
    margin-top: 0px;
  }
`

const RightImageWrapper = styled.div`
  display: none;
  pointer-events: none;
  @media (min-width: ${props => props.theme.awesomegrid.breakpoints.lg}rem) {
    position: absolute;
    top: 100px;
    bottom: 0px;

    display: flex;
    align-items: baseline;
    justify-content: center;

    left: auto;
    right: 32px;
    width: 340px;
  }
  @media (min-width: 1640px) {
    align-items: center;
    top: 0px;
    right: 0px;
    width: calc(100vw - (76.875rem + (100vw - 76.875rem) / 2));
    left: auto;
  }
`

const RightImage = styled.img`
  max-width: 596px;
  width: 100%;
  margin-top: 0px;

  @media (min-width: ${props => props.theme.awesomegrid.breakpoints.lg}rem) {
    margin-top: -60px;
  }

  @media (min-width: ${props => props.theme.awesomegrid.breakpoints.xl}rem) {
    margin-top: 0px;
  }
`

const StyledA = styled.a`
  color: inherit;
  text-decoration: inherit;
  cursor: inherit;
`

type Props = {
  title?: string
  button_text?: string
  padding_top: { sm: number; md: number; lg: number }
  padding_bottom: { sm: number; md: number; lg: number }
  right_image?: boolean
  cards: Array<{
    col: 6 | 12
    CardsData: CardsProps
  }>
}

const GamesModule: React.FC<Props> = ({
  title,
  button_text,
  padding_top,
  padding_bottom,
  right_image,
  cards,
}) => {
  return (
    <ModuleWrapper padding_top={padding_top} padding_bottom={padding_bottom}>
      {right_image && (
        <RightImageWrapper>
          <RightImage
            src={Tharsis}
            alt="Tharsis"
            className="superman_fly"
            draggable="false"
          />
        </RightImageWrapper>
      )}
      <Container>
        <Row>
          {title && (
            <Col xs={12}>
              <TitleWrapper>
                <Title level={1} variable="primary" color="black" uppercase>
                  {title}
                </Title>
              </TitleWrapper>
            </Col>
          )}
          {cards.map((card, index) => (
            <Col md={12} lg={card.col}>
              <CardWrapper>
                <GamesCards
                  key={index}
                  image={card.CardsData.image}
                  title={card.CardsData.title}
                  developer={card.CardsData.developer}
                  developer_link={card.CardsData.developer_link}
                  description={card.CardsData.description}
                  avaliable_platforms={card.CardsData.avaliable_platforms}
                  trailer={card.CardsData.trailer}
                />
              </CardWrapper>
            </Col>
          ))}
          {button_text && (
            <Col xs={12}>
              <ButtonWrapper>
                <Button type="tertiary">
                  <StyledA href="/games-library">{button_text}</StyledA>
                </Button>
              </ButtonWrapper>
            </Col>
          )}
        </Row>
      </Container>
    </ModuleWrapper>
  )
}

export default GamesModule
