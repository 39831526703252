import React from "react"
import styled from "styled-components"
import { Link } from "gatsby-plugin-react-i18next"
import Subtitle from "../../Commons/Subtitle"
import Paragraph from "../../Commons/Paragraph"
import Icon from "../../Icons"
import PlayButtonIcon from "../../../images/Hero/play-icon.svg"


const CardImageContainer = styled.div`
  position: relative;
`

const CardImage = styled.img`
  border-radius: 24px 24px 0px 0px;
  width: 100%;
  object-position: center;
  object-fit: cover;

  @media (min-width: ${props => props.theme.awesomegrid.breakpoints.md}rem) {
    border-radius: 24px;
    height: 100%;
  }

  @media (min-width: ${props => props.theme.awesomegrid.breakpoints.lg}rem) {
    border-radius: 24px 24px 0px 0px;
    max-height: 334px;
    height: auto;
  }
`

const CardBody = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 24px 24px 16px;
  background: ${props => props.theme.variables.forge.neutral};

  @media (min-width: ${props => props.theme.awesomegrid.breakpoints.md}rem) {
    padding: 0;
    margin-left: 64px;
  }

  @media (min-width: ${props => props.theme.awesomegrid.breakpoints.lg}rem) {
    padding: 64px 64px 40px;
    margin-left: 0;
    height: 100%;
  }
`

const CardContainer = styled.div`
  display: flex;
  flex-direction: column;

  @media (min-width: ${props => props.theme.awesomegrid.breakpoints.md}rem) {
    display: grid;
    grid-template-columns: 3fr 5fr;
    padding: 32px;
    background: ${props => props.theme.variables.forge.neutral};
    border-radius: 16px 16px 0px 0px;
  }

  @media (min-width: ${props => props.theme.awesomegrid.breakpoints.lg}rem) {
    height: 100%;
    display: flex;
    flex-direction: column;
    padding: 0;
    border-radius: 24px 24px 0px 0px;
  }
`

const AvaliablePlatforms = styled.div`
  margin-top: 46px;

  @media (max-width: ${props => props.theme.awesomegrid.breakpoints.md - 0.01}rem) {
    margin-top: 15px;
  }

  @media (min-width: ${props => props.theme.awesomegrid.breakpoints.lg}rem) {
    margin-top: 78px;
  }
`
const PlatformList = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin: -2px;
  margin-top: 16px;


  @media (min-width: ${props => props.theme.awesomegrid.breakpoints.md}rem) {
    margin: -4px;
    margin-top: 16px;
  }
`
const TitleWrapper = styled.div`
  margin-bottom: 16px;
`
const PlatformCard = styled.div`
  height: 36px;
  min-width: 43px;
  max-width: 43px;
  background: ${props => props.theme.variables.secondary.yellow};
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
  cursor: pointer;
  margin: 2px;

  svg {
    width: 22px;
  }

  &:hover {
    background: #000;

    svg path {
      fill: #fff !important;
    }

  }


  @media (min-width: ${props => props.theme.awesomegrid.breakpoints.md}rem) {
    height: 56px;
    max-width: 62.93px;
    width: 62.93px;
    min-width: 62.93px;
    border-radius: 8px;
    margin: 4px;

    svg {
      width: 28px;
    }
  }

  @media (min-width: ${props => props.theme.awesomegrid.breakpoints.lg}rem) {
    height: 80px;
    min-width: 84px;
    width: 84px;
    max-width: 84px;

    svg {
      width: 48px;
    }
  }
`

const HeadWrapper = styled.div`
  p {
    white-space: pre-line;
  }
`

const PlatformCardFooter = styled.div`
  display: grid;
  grid-template-columns: repeat(5, 1fr);
`
type FooterItemProps = {
  variable?: string
  color: string
}

const PlatformCardFooterItem = styled.div<FooterItemProps>`
  height: 10px;
  background-color: ${props =>
    props.theme.variables[props?.variable ?? "secondary"][props?.color]
      ? props.theme.variables[props?.variable ?? "secondary"][props?.color]
      : props.theme.variables.primary.black};
`

const ParagraphWrapper = styled.div`
  p {
    @media (max-width: ${props => props.theme.awesomegrid.breakpoints.lg - 0.01}rem) {
      font-weight: 800;
    }
  }
`
const StyledA = styled.a`
  color: inherit;
  text-decoration: inherit;
  cursor: inherit;
`

const PlayButtonA = styled.a`
  position: absolute;
  width: 56px;
  height: 56px;
  border-radius: 50%;
  background-color: ${props => props.theme.variables.secondary.yellow};
  bottom: 20px;
  right: 20px;
  cursor: pointer;

  @media (min-width: ${props => props.theme.awesomegrid.breakpoints.sm}rem) {
    width: 88px;
    height: 88px;
  }

  @media (min-width: ${props => props.theme.awesomegrid.breakpoints.md}rem) {
    width: 100px;
    height: 100px;
  }

  transition: all 1200ms cubic-bezier(0.19, 1, 0.22, 1);

  &:hover {
    transform: scale(1.1);
  }

  svg {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-40%, -50%);
    width: 24px;
    height: 27px;

    @media (min-width: ${props => props.theme.awesomegrid.breakpoints.sm}rem) {
      width: 32px;
      height: 26px;
    }

    @media (min-width: ${props => props.theme.awesomegrid.breakpoints.md}rem) {
      width: 52px;
      height: 42px;
    }
  }
`



export type CardsProps = {
  image: string
  title: string
  developer?: string
  developer_link?: string
  description: string
  coming_soon?: boolean
  avaliable_platforms: Array<{
    icon: string
    size: string
    link: string
  }>
  trailer: string
}

const GamesCards: React.FC<CardsProps> = ({
  image,
  title,
  developer,
  developer_link,
  description,
  coming_soon,
  avaliable_platforms,
  trailer,
}) => {

  return (
    <>
      <CardContainer>
        <CardImageContainer>
          <CardImage src={image} draggable="false" />
          {trailer && <PlayButtonA href={trailer} target="_blank">
            <PlayButtonIcon />
          </PlayButtonA>}
        </CardImageContainer>
        <CardBody>
          <HeadWrapper>
            <TitleWrapper>
              <Subtitle level={1} variable="primary" color="black">
                {title}
              </Subtitle>
            </TitleWrapper>

            {developer && (
              <TitleWrapper>
                <Paragraph level={1} variable="primary" color="black" weight="700">
                  Developed by: {
                    developer_link ? (
                      <Link
                        to={developer_link}
                        target="_blank"
                      >
                        {developer}
                      </Link>
                    ) : developer
                  }
                </Paragraph>
              </TitleWrapper>
            )}

            <Paragraph level={1} variable="primary" color="black" weight="500">
              {description}
            </Paragraph>
          </HeadWrapper>


          <AvaliablePlatforms>
            <ParagraphWrapper>
              <Paragraph level={2} variable="primary" color="black" weight="700">
                {
                  coming_soon
                    ? 'Coming soon'
                    : avaliable_platforms.length
                      ? 'Available on'
                    : ''
                }
              </Paragraph>
            </ParagraphWrapper>
            <PlatformList>
              {avaliable_platforms.map((platform, index) => (
                <PlatformCard key={index}>
                  <StyledA href={platform.link} target="_blank">
                    <Icon
                      icon={platform.icon}
                      size={platform.size || 48}
                      color="#111111"
                    />
                  </StyledA>
                </PlatformCard>
              ))}
            </PlatformList>
          </AvaliablePlatforms>
        </CardBody>
      </CardContainer>
      <PlatformCardFooter>
        <PlatformCardFooterItem variable="secondary" color="yellow" />
        <PlatformCardFooterItem variable="secondary" color="orange" />
        <PlatformCardFooterItem variable="secondary" color="blue" />
        <PlatformCardFooterItem variable="secondary" color="pink" />
        <PlatformCardFooterItem variable="secondary" color="purple" />
      </PlatformCardFooter>
    </>
  )
}

export default GamesCards
